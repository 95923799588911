.contact-container {
  flex: 1; /* Make sure it takes up the remaining space in the parent */
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; 
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), 
    url('../assets/images/saas.jpg'); 
  background-size: cover;
  background-position: center;
}

.centered-text-contact {
  background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
  padding: 5rem; 
  border-radius: 10px;
  position: relative;
  z-index: 2;
  font-size: 2rem;
  display: inline-block;
}


@media (max-width: 768px) {
  .centered-text-contact {
    background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
    padding: 2rem; 
    border-radius: 10px;
    position: relative;
    z-index: 2;
    color: black;
    font-size: 1.5rem;
    display: inline-block;
  }
}