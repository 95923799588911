/* Scroll to Top Button Styles */
.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 50px;
  height: 50px;
  background-color: rgba(255, 193, 7, 0.8); /* Golden color */
  border: 2px solid rgba(255, 193, 7, 0.9);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top svg {
  fill: white;
  width: 20px;
  height: 20px;
}

/* Show the button when the user scrolls down */
.scroll-to-top.visible {
  opacity: 1;
}
