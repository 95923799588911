nav {
  box-shadow: 0 4px 2px -2px gray;
  height: 10%; /* Navbar takes 10% of the viewport height */
  display: flex;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  position: relative; /* Ensures the dropdown menu is positioned correctly */
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none; /* Hidden by default, visible via JavaScript */
  flex-direction: column; /* For dropdown */
  width: 100%; /* Takes full width on small screens */
  align-items: center;
  text-align: center; 
}

ul li {
  min-width: 150px;
  margin: 0;
  width: 100%; /* Ensures full width for list items */
}

a {
  text-decoration: none;
  display: block; /* Block level for easier styling */
  padding: 10px;
}

.logo{
  min-width: 200px;
}

@media (min-width: 768px) {
  /* Larger screens */
  ul {
    display: flex; /* Flex row for larger screens */
    flex-direction: row; /* Horizontal list */
    justify-content: flex-end;
    position: static;
    width: auto;
  }

  ul li {
    margin: 0 1rem;
  }

  nav {
    box-shadow: 0 4px 2px -2px gray;
    height: 10vh; /* Navbar takes 10% of the viewport height */
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; /* Ensures the dropdown menu is positioned correctly */
  }
}
