.App {
  text-align: center;
  font-size: large;
}

.review-card {
  padding: 20px;
  border-radius: 10px;
  background-color: #f9f9f9;
}

h1 {
  margin-bottom: 20px;
}

h2 {
  font-size: 30px;
  font-weight: bolder;
  margin-bottom: 10px;
}

p {
  font-size: 25px;
  margin-bottom: 10px;
}
/* Basic styles for the arrow buttons */
.arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: #007bff;
  cursor: pointer;
  z-index: 1;
  padding: 0;
}

.prev-arrow {
  left: -40px; /* Position on the left side of the slider */
}

.next-arrow {
  right: -40px; /* Position on the right side of the slider */
}

.arrow-button:hover {
  color: #0056b3; /* Darker blue when hovering */
}
