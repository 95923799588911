.home-container {
  flex: 1; /* Make sure it takes up the remaining space in the parent */
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; 
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), 
    url('../assets/images/saas.jpg'); 
  background-size: cover;
  background-position: center;
}

.centered-text-home {
  padding: 5rem; 
  font-weight: bold;
  border-radius: 10px;
  position: relative;
  z-index: 2;
  color: white;
  font-size: 2rem;
  display: inline-block;
}


@media (max-width: 768px) {
  .centered-text-home {
    background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7));
    padding: 2rem; 
    border-radius: 10px;
    position: relative;
    z-index: 2;
    color: white;
    font-size: 1.5rem;
    display: inline-block;
  }
}


@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Services Section - General Styles */
.services-section {
  padding: 2rem 0;
}

/* Flexbox for service items */
.services-section .container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

/* Service Item Styles */
.service-item {
  width: 30%; /* Set initial width to 30% for larger screens */
  margin-bottom: 2rem;
  opacity: 0;
  transform: translateX(-100%); /* Positioned off-screen to the left */
  transition: transform 3s ease-out, opacity 3s ease-out;
}

.service-item.in-view {
  animation: slide-in-left 3s forwards; /* Trigger the animation when in view */
  opacity: 1; /* Make the element fully visible */
  transform: translateX(0); /* Reset the position to its original place */
}

/* Responsive adjustments for medium screens */
@media (max-width: 1024px) {
  .service-item {
    width: 45%; /* Adjust to show 2 items in a row for medium screens */
    animation: slide-in-left 2s forwards; /* Re-apply animation for medium screens */
  }
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .service-item {
    width: 100%; /* Stack items vertically for smaller screens */
    animation: slide-in-left 2s forwards; /* Re-apply animation for small screens */
  }

  .service-item img {
    height: auto; /* Ensure the image maintains aspect ratio */
  }

  .centered-text-home {
    font-size: 1.5rem;
    padding: 2rem;
  }
}

/* Smallest screens */
@media (max-width: 480px) {
  .service-item {
    width: 100%; /* Stack items vertically on small screens */
    animation: slide-in-left 1.5s forwards; /* Re-apply animation for very small screens */
  }

  .service-item img {
    height: auto; /* Maintain aspect ratio on small screens */
    width: 100%; /* Make images responsive */
  }

  .centered-text-home {
    font-size: 1.25rem; /* Adjust font size for smaller screens */
  }
}
